<template>
  <v-card tile>
    <v-card-text>
      <v-container fluid>
        <v-row>
          <v-col cols="12" v-if="listOccupazione.length > 0">
            <v-data-table dense :headers="listOccupazioneHeaders" :items="listOccupazioneWithUndoInfo">
              <template #item.actions="slotProps">
                <v-icon v-if="slotProps.item.canUndo" @click="deleteMove(slotProps.item)">mdi-delete</v-icon>
              </template>
            </v-data-table>
          </v-col>
        </v-row>

        <v-row>
          <v-col sm="6" md="3">
            <v-combobox clearable v-model="selectedPark" :items="parks" label="Parco binari" item-text="codice" />
          </v-col>
        </v-row>
        <v-row v-if="railsPanelVisible">
          <v-col sm="4" md="8">
            <v-radio-group v-model="selectedRail" row>
              <v-radio v-for="rail in rails" :key="rail.id" :value="rail" :label="`B. ${rail.numero}`" />
            </v-radio-group>
          </v-col>
          <v-col sm="4" md="2">
            <v-text-field type="number" :rules="[validationRules.greaterThanOrEqualToZero]" v-model="railPosition" :label="tLabel('posizioneBinario')" />
          </v-col>

          <v-col sm="4" md="2">
            <v-datetime-picker label="Data / ora" v-model="operationDate" />
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn x-large v-if="gateInEnabled" @click="gateIn" color="primary"> GATE IN </v-btn>
      <v-btn x-large v-if="btnAnnullaGateInEnabled" :disabled="flgAnnullaGI" @click="annullaGateIn" color="primary"> ANNULLA GATE IN </v-btn>
      <v-btn x-large v-if="gateOutEnabled" :disabled="disableGateOut" @click="prepareGateOut" color="primary"> GATE OUT </v-btn>
      <v-btn x-large v-if="moveEnabled" @click="shunting" color="primary"> SPOSTA </v-btn>
    </v-card-actions>

    <v-dialog v-model="confirmDialogVisible" persistent max-width="290" header="Conferma">
      <v-card>
        <v-card-text>
          {{ confirmMessage }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="onConfirm" small>Dissocia</v-btn>
          <v-btn @click="gateOut" small>Mantieni</v-btn>
          <v-btn @click="confirmDialogVisible = false" small>Annulla</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import toastMixin from "../../../mixins/ToastMixin";
import convoyVisualizationMixins from "../mixins/convoyVisualizationMixins";

export default {
  name: "convoyGateAndMoveManagement",
  data() {
    return {
      rails: [],
      selectedPark: null,
      selectedRail: null,
      railPosition: 0,
      operationDate: null,
      flgAnnullaGI: false,
      confirmMethod: null,
      confirmData: null,
      confirmMessage: null,
      confirmDialogVisible: null,
      disableGateOut: true,
      listOccupazioneHeaders: [
        { text: "Inizio", value: "startDate" },
        { text: "Fine", value: "endDate" },
        { text: "Parco", value: "parcoBinari" },
        { text: "Binario", value: "binario.label" },
        { text: "Pos.Inizio", value: "posizioneInizio" },
        { text: "Pos.Fine", value: "posizioneFine" },
        { text: "Stato", value: "stato" },
        { text: "Manovra", value: "tipoManovra" },
        { text: "", value: "actions", width: "10px" },
      ],
    };
  },
  props: {
    convoglio: {
      type: Object,
      required: true,
    },
    parks: {
      type: Array,
      default: () => [],
    },
    listOccupazione: {
      type: Array,
      default: () => [],
    },
  },
  mixins: [convoyVisualizationMixins, toastMixin],
  async mounted() {
    this.fetchData();
  },
  computed: {
    railsPanelVisible() {
      return this.rails && this.rails.length;
    },
    gateInEnabled() {
      return this.convoglio.stato && this.convoglio.stato.toLowerCase() === "creato";
    },
    gateOutEnabled() {
      return this.convoglio.stato && this.convoglio.stato.toLowerCase() === "prontouscita";
    },
    moveEnabled() {
      return this.convoglio.stato && this.convoglio.stato.toLowerCase() === "interminal";
    },
    btnAnnullaGateInEnabled() {
      return this.convoglio.stato && this.convoglio.stato.toLowerCase() === "interminal";
    },
    listOccupazioneWithUndoInfo() {
      if (this.listOccupazione.length > 0) {
        const last = this.listOccupazione[this.listOccupazione.length - 1];
        if (last.tipoManovra) last.canUndo = true;
      }
      return this.listOccupazione;
    },
  },
  watch: {
    convoglio: function () {
      this.fetchData();
    },
    selectedPark: async function (newValue) {
      if (newValue) {
        this.rails = await this.$api.railData.getRailwayData(newValue.codice);
        this.selectedRail = this._.find(this.rails, (r) => this.convoglio.binario && r.id === this.convoglio.binario.id);
        this.railPosition = this.convoglio.posizioneBinario || 0;
        this.disableGateOut = this.checkGateOut();
      } else {
        this.rails = [];
        this.selectedRail = null;
        this.railPosition = 0;
      }
    },
    selectedRail: async function () {
      this.disableGateOut = this.checkGateOut();
    },
    operationDate: async function () {
      this.disableGateOut = this.checkGateOut();
    },
  },

  methods: {
    async gateIn() {
      try {
        if (this.railPosition && this.railPosition < 0) {
          this.showError("Impostare un valore positivo per la posizione");
          return;
        }
        await this.$api.trainData.gateInConvoglio(this.convoglio, this.selectedRail, this.railPosition, this.operationDate);
        this.showSuccess("Gate in effettuato correttamente");
      } catch (e) {
        console.log("Gate in error:", e);
        this.showError(e.detailMessage || e);
      } finally {
        this.$eventBus.$emit("convoyReload");
      }
    },

    async annullaGateIn() {
      try {
        await this.$api.trainData.annullaGateInConvoglio(this.convoglio);
        this.showSuccess("Annullamento gate in effettuato correttamente");
      } catch (e) {
        console.log("Annulla gate in error:", e);
        this.showError(e.detailMessage || e);
      } finally {
        this.$eventBus.$emit("convoyReload");
      }
    },
    async prepareGateOut() {
      try {
        await this.$api.trainData.prepareGateOut(this.convoglio).then((response) => {
          console.log("response:", response);
          if (response == true) {
            console.log("Appertura dialog. Dissocia carri convogli da viaggio ");
            this.confirmMessage =
              "Esistono altri convogli all'interno del terminal con questo viaggio di uscita. Selezionare se mantenere o annullare il viaggio dai convogli presenti? ";
            this.confirmMethod = "dissociaCarri";
            this.confirmData = this.convoglio;

            this.doConfirm(this.confirmMessage, this.confirmMethod, this.confirmData);
          } else {
            console.log("gate out");
            this.gateOut();
          }
        });
      } catch (e) {
        console.log("Gate Out error:", e);
        this.showError(e.detailMessage || e);
      } finally {
        this.$eventBus.$emit("convoyReload");
      }
    },

    async dissociaCarri() {
      try {
        console.log("dissocia carri");
        await this.$api.trainData.dissociaCarri(this.convoglio);
        this.gateOut();
      } catch (e) {
        console.log("Dissocia carri convoglio error:", e);
        this.showError(e.detailMessage || e);
      } finally {
        this.$eventBus.$emit("convoyReload");
      }
    },

    doConfirm(confirmMessage, confirmMethod, confirmData) {
      this.confirmMessage = confirmMessage;
      this.confirmData = confirmData;
      this.confirmMethod = confirmMethod;
      this.confirmDialogVisible = true;
    },

    onConfirm() {
      this.confirmDialogVisible = false;
      let method = this[this.confirmMethod];
      if (method == null) {
        console.warn("Confirm method null:", this.confirmMethod);
        return;
      }
      method(this.confirmData);
    },

    async gateOut() {
      try {
        await this.$api.trainData.gateOutConvoglio(this.convoglio, this.operationDate);
        this.showSuccess("Gate out effettuato correttamente");
      } catch (e) {
        console.log("Gate Out error:", e);
        this.showError(e.detailMessage || e);
      } finally {
        this.$eventBus.$emit("convoyReload");
      }
    },

    async shunting() {
      try {
        if (this.railPosition && this.railPosition < 0) {
          this.showError("Impostare un valore positivo per la posizione");
          return;
        }
        await this.$api.trainData.shuntingConvoglio(this.convoglio, this.selectedRail, this.railPosition, this.operationDate);
        this.showSuccess("Shunting in effettuato correttamente");
      } catch (e) {
        console.log("shunting error:", e);
        this.showError(e.detailMessage || e);
      } finally {
        this.$eventBus.$emit("convoyReload");
      }
    },
    async fetchData() {
      if (!this.convoglio.id) return;
      if (this.convoglio.binario && this.convoglio.binario.codiceParcoBinari) {
        this.rails = await this.$api.railData.getRailwayData(this.convoglio.binario.codiceParcoBinari);
        this.selectedPark = this._.find(this.parks, (p) => p.codice === this.convoglio.binario.codiceParcoBinari);
        this.selectedRail = this._.find(this.rails, (r) => this.convoglio.binario && r.id === this.convoglio.binario.id);
        this.railPosition = this.convoglio.posizioneBinario;
        var date = new Date();
        date.setMinutes(date.getMinutes() + 1);
        this.operationDate = date;
      }
      this.flgAnnullaGI = await this.$api.trainData.getEnableBtnAnnullaGateIn(this.convoglio);
    },
    async deleteMove(move) {
      const tipo = move.tipoManovra;
      const altroConvoglio = tipo == "UN" || tipo == "SP";
      let msg = "Confermi cancellazione manovra?";
      if (altroConvoglio) msg = "Attenzione. L'operazione coinvolge anche un altro convoglio\n\n " + msg;
      const answer = window.confirm(msg);
      if (!answer) return;
      console.log("Delete lastMove :" + move);
      try {
        const convoglioId = await this.$api.trainData.undoLastMove(move.id);
        this.showSuccess("Manovra cancellata");
        if (convoglioId != this.convoglio.id) this.$router.push({ path: "/rails/ConvoyVisualization/" + convoglioId });
        else this.$eventBus.$emit("convoyReload");
      } catch (e) {
        console.log("Errore:", e);
        this.showError(e.detailMessage || e);
      }
    },

    checkGateOut() {
      return this.selectedPark == null || this.selectedRail == null || this.operationDate == null;
    },
  },
};
</script>
