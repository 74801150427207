<template>
  <v-container class="info-panel">
    <v-row align="center">
      <v-col cols="12">
        <v-container class="info-panel">
          <v-row no-gutters>
            <v-col cols="12" sm="4">
              <span
                >{{ tLabel("Arrivo previsto") }}: <b>{{ convoglio.eta | formatDateTime }}</b></span
              >
            </v-col>
            <v-col cols="12" sm="4">
              <span
                >{{ tLabel("Partenza prevista") }}: <b>{{ convoglio.etd | formatDateTime }}</b></span
              >
            </v-col>
            <v-col cols="12" sm="4">
              <span
                >{{ tLabel("Parco binari") }}: <b>{{ convoglio.binario != null ? convoglio.binario.codiceParcoBinari : "" }}</b></span
              >
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" sm="4">
              <span
                >{{ tLabel("arrivo") }}: <b>{{ convoglio.ata | formatDateTime }}</b></span
              >
            </v-col>
            <v-col cols="12" sm="4">
              <span
                >{{ tLabel("partenza") }}: <b>{{ convoglio.atd | formatDateTime }}</b></span
              >
            </v-col>
            <v-col cols="12" sm="4">
              <span
                >{{ tLabel("Binario") }}: <b>{{ convoglio.binario != null ? convoglio.binario.numero : "" }}</b></span
              >
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" sm="4">
              <span
                >{{ tLabel("Inizio lav.") }}: <b>{{ convoglio.processingTimeStart | formatDateTime }}</b></span
              >
            </v-col>
            <v-col cols="12" sm="4">
              <span
                >{{ tLabel("Fine lav.") }}: <b>{{ convoglio.processingTimeEnd | formatDateTime }}</b></span
              >
            </v-col>
            <v-col cols="12" sm="4">
              <span
                >{{ tLabel("Occupazione binario") }}: <b>{{ convoglio.ata | timeFromDate }} - {{ convoglio.atd | timeFromDate }}</b></span
              >
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" sm="4">
              <span
                >{{ tLabel("Scarico") }}: <b>{{ convoglio.exportProcessingTimeStart | timeFromDate }} - {{ convoglio.exportProcessingTimeEnd | timeFromDate }}</b></span
              >
            </v-col>
            <v-col cols="12" sm="4">
              <span
                >{{ tLabel("Carico") }}: <b>{{ convoglio.importProcessingTimeStart | timeFromDate }} - {{ convoglio.importProcessingTimeEnd | timeFromDate }}</b></span
              >
            </v-col>
            <v-col cols="12" sm="4">
              <span
                >{{ tLabel("Stato") }}: <b>{{ convoglio.stato }}</b></span
              >
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
    <v-divider />
    <v-row v-if="!timesOnly && executionVoyage">
      <v-col cols="12" sm="6">
        <v-row no-gutters>
          <v-col cols="12">
            <span>{{ tLabel("viaggio lavorazione") }}:</span>
          </v-col>
          <v-col cols="12">
            <ul>
              <li>
                <router-link
                  :title="`${isExecution && isExecution === true ? 'Lavora' : 'Pianifica'} viaggio`"
                  :to="`/callTreno/${isExecution && isExecution === true ? 'lavorazione' : 'pianificazione'}Viaggio${
                    isDepartureExecutionVoyage && isDepartureExecutionVoyage === true ? 'Partenza' : 'Arrivo'
                  }/${this.executionVoyage.id}`"
                >
                  <v-btn text>
                    <b>{{ executionVoyage.nodoArrivo ? executionVoyage.nodoArrivo.codice : "" }} ({{ executionVoyage.codice }})</b>
                    <span v-if="executionVoyage.atd">
                      ATD: <b>{{ executionVoyage.atd }}</b></span
                    >
                    <span v-else>
                      ETD: <b>{{ executionVoyage.etd }}</b></span
                    >
                  </v-btn>
                </router-link>
              </li>
            </ul>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row v-if="!timesOnly">
      <v-col cols="4" sm="6">
        <v-row no-gutters>
          <v-col cols="12">
            <span>{{ tLabel("viaggi in arrivo (provenienza)") }}:</span>
          </v-col>
          <v-col cols="12">
            <ul>
              <li v-for="viaggio in tripsIn" :key="viaggio.id">
                <router-link
                  :title="`${isExecution && isExecution === true ? 'Lavora' : 'Pianifica'} viaggio`"
                  :to="`/callTreno/${isExecution && isExecution === true ? 'lavorazione' : 'pianificazione'}ViaggioArrivo/${viaggio.id}`"
                >
                  <v-btn text>
                    <b>{{ viewNomeViaggio() ? viaggio.nome : viaggio.codice }} ({{ viaggio.nodoPartenza.codice }} )</b>
                    <!--
                        <b>{{ viaggio.nodoPartenza.codice }} ({{(viewNomeViaggio() ? viaggio.nome: viaggio.codice)}})</b>                                    
                        <span v-if="viaggio.ata"> ATA: <b>{{ viaggio.ata }}</b></span>
                        <span v-else>ETA: <b>{{ viaggio.eta }}</b></span>
                        <span>{{tLabel("S.OPERATIVO")}}: <b>{{ viaggio.statoOperativo }}</b></span> 
                    -->
                  </v-btn>
                </router-link>
              </li>
            </ul>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="4" sm="6">
        <v-row no-gutters>
          <v-col cols="12">
            <span>viaggi in partenza (destinazione):</span>
          </v-col>
          <v-col cols="12">
            <ul>
              <li v-for="viaggio in tripsOut" :key="viaggio.id">
                <router-link
                  :title="`${isExecution && isExecution === true ? 'Lavora' : 'Pianifica'} viaggio`"
                  :to="`/callTreno/${isExecution && isExecution === true ? 'lavorazione' : 'pianificazione'}ViaggioPartenza/${viaggio.id}`"
                >
                  <v-btn text>
                    <b>{{ viaggio.nodoArrivo.codice }} ({{ viewNomeViaggio() ? viaggio.nome : viaggio.codice }})</b>
                    <span v-if="viaggio.atd">
                      ATD: <b>{{ viaggio.atd }}</b></span
                    >
                    <span v-else>
                      ETD: <b>{{ viaggio.etd }}</b></span
                    >
                  </v-btn>
                </router-link>
              </li>
            </ul>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import railsMixins from "../rails/mixins/railsMixins";

export default {
  name: "ConvoyInfoPanelFull",
  mixins: [railsMixins],
  data() {
    return {};
  },
  props: {
    convoglio: {
      type: Object,
      required: true,
    },
    timesOnly: {
      type: Boolean,
      required: false,
    },
    isExecution: {
      type: Boolean,
      required: false,
      default: false,
    },
    isDepartureExecutionVoyage: {
      type: Boolean,
      required: false,
      default: false,
    },
    executionVoyage: {
      type: Object,
      required: false,
    },
  },
  computed: {
    tripsIn() {
      const tripsIn = [];
      this._.forIn(this.convoglio.listCarri, (carro) => {
        if (carro.viaggioIn && carro.viaggioIn.nodoPartenza && !tripsIn.find((v) => carro.viaggioIn.nodoPartenza.codice === v.nodoPartenza.codice)) {
          //tripsIn.push(carro.viaggioIn.nodoPartenza.codice);
          tripsIn.push(carro.viaggioIn);
        }
      });
      return tripsIn;
    },
    tripsOut() {
      const tripsOut = [];
      this._.forIn(this.convoglio.listCarri, (carro) => {
        if (carro.viaggioOut && carro.viaggioOut.nodoArrivo && !tripsOut.find((v) => carro.viaggioOut.nodoArrivo.codice === v.nodoArrivo.codice)) {
          //tripsOut.push(carro.viaggioOut.nodoArrivo.codice);
          tripsOut.push(carro.viaggioOut);
        }
      });
      return tripsOut;
    },
  },
  methods: {},
};
</script>

<style scoped>
ul {
  list-style: none;
}
.info-panel {
  padding: 0px !important;
}
</style>
