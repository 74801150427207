<template>
  <div>
    <v-container fluid>
      <v-row no-gutters>
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Ricerca per targa o parco destinazione"
          single-line hide-details style="max-width: 300px" />
      </v-row>
      <v-row no-gutters>
        <v-col>
          <!-- -------- CARRI SELECTED --------<br/> 
          {{this.carriSelected}}  <br/>
          --------------------------------<br/><br/>
          -------- VALUE          --------<br/>
          {{this.value}}<br/>
          --------------------------------<br/><br/> -->
          <v-data-table dense v-model="carriSelected" :headers="headers" :items="carriConvoglio" :items-per-page="10000"
            :search="search" show-select hide-default-footer>

            <template v-slot:item.visitaCarro.targa="{ item }">
              <v-tooltip top :disabled="!item.visitaCarro.danni">
                <template v-slot:activator="{ on, attrs }">
                  <div class="trip-table-detail" >
                    <span>
                      {{ item.visitaCarro.targa }}
                    </span>
                    <v-icon v-if="!!item.visitaCarro.danni" color="warning" v-bind="attrs" v-on="on">mdi-alert-outline</v-icon>
                  </div>
                </template>
                <span style="color: white;">Danni: {{ item.visitaCarro.danni }}</span>
              </v-tooltip>
            </template>
            <template v-slot:item.viaggioIn="{ item }">
              <div class="trip-table-detail">
                <span v-if="item.viaggioIn">
                  {{
                    `${viewNomeViaggio() ? item.viaggioIn.nome : item.viaggioIn.codice} - ${item.viaggioIn.nodoPartenza ?
                      item.viaggioIn.nodoPartenza.descrizione : "N/A"} - ${item.viaggioIn.eta
                      }`
                  }}
                </span>
                <span v-else> N/A </span>
              </div>
            </template>
            <template v-slot:item.viaggioOut="{ item }">
              <div class="trip-table-detail">
                <span v-if="item.viaggioOut">
                  {{
                    `${viewNomeViaggio() ? item.viaggioOut.nome : item.viaggioOut.codice} - ${item.viaggioOut.nodoArrivo ?
                      item.viaggioOut.nodoArrivo.descrizione : "N/A"} - ${item.viaggioOut.etd
                      }`
                  }}
                  <v-icon class="mb-1 ml-2" @click="removeWagonToDepartureTrip(item)"> mdi-close </v-icon>
                </span>
                <span v-else> N/A </span>
              </div>
            </template>
            <template v-slot:item.parcoDestinazione="{ item }">
              <div class="trip-table-detail">
                <v-combobox clearable v-bind:value="item.visitaCarro.codiceParcoDestinazione" :items="parks"
                  item-text="codice" @change="saveWagonParkDestination($event, item)" />
              </div>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import railsMixins from "../../rails/mixins/railsMixins";
import toastMixin from "../../../mixins/ToastMixin";
import convoyVisualizationMixins from "../mixins/convoyVisualizationMixins";

export default {
  name: "convoyWagonSelectionManagement",
  data() {
    return {
      search: "",
      carriSelected: [],
      headers: [
        { text: "Targa", value: "visitaCarro.targa", sortable: true },
        { text: "Viaggio arrivo (prov.)", value: "viaggioIn", sortable: false },
        { text: "Viaggio partenza (dest.)", value: "viaggioOut", sortable: false },
        { text: "Parco destinazione ", value: "visitaCarro.codiceParcoDestinazione", sortable: false, width: "0px", class: "hidden" },
        { text: "Modifica Parco dest.", value: "parcoDestinazione", sortable: false },
      ],
    };
  },
  created() {
    this.$eventBus.$on("clearFilter", async () => {
      this.search = "";
    });
  },

  props: {
    value: {
      type: Array,
      default: () => [],
    },
    convoglio: {
      type: Object,
      required: true,
    },
    parks: {
      type: Array,
      default: () => [],
    },
  },
  mixins: [convoyVisualizationMixins, toastMixin, railsMixins],
  computed: {
    carriConvoglio() {
      return this._.orderBy(this.convoglio.listCarri, [(cc) => cc.posizione]);
    },
  },
  methods: {
    async saveWagonParkDestination(event, carroConvoglio) {
      try {
        carroConvoglio.visitaCarro.parcoDestinazione = event;
        await this.$api.trainData.associaCarroParco(carroConvoglio);
      } catch (e) {
        this.showError(e);
      } finally {
        this.$eventBus.$emit("convoyReload");
      }
    },
    async removeWagonToDepartureTrip(carroConvoglio) {
      if (!carroConvoglio.listUti.length) {
        try {
          const newConvoyRef = await this.$api.trainData.rimuoviCarriViaggioPartenza(carroConvoglio.viaggioOut, carroConvoglio);
          this.showSuccess(
            `Viaggio ${carroConvoglio.viaggioOut.codice} - ${carroConvoglio.viaggioOut.nodoArrivo ? carroConvoglio.viaggioOut.nodoArrivo.descrizione : "NA"} - ${carroConvoglio.viaggioOut.etd
            } correttamente rimosso`
          );
        } catch (e) {
          this.showError(e);
        } finally {
          this.$eventBus.$emit("convoyReload");
        }
      } else {
        this.showError("Impossibile rimuovere carro, sono presenti unità a bordo");
      }
    },
  },
  watch: {
    value: {
      handler: function (_) {
        // console.log("WATCH value", this.value, this.carriSelected);
        this.carriSelected = this.value;
      },
    },
    carriSelected: {
      handler: function (_) {
        // console.log("WATCH carriSelected", this.value, this.carriSelected);
        this.$emit("input", this.carriSelected);
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.trip-table-detail {
  width: 100%;
  text-align: center;
}

.hidden {
  display: none;
  border: 2px solid red;
}
</style>
