<template>
  <div>
    <v-container 
      v-if="convogliGeneratiIdList.length > 0"
      fluid
    >
      <v-row no-gutters>
        <v-col>
          <h5>Convogli separati</h5>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col>
          <ul>
            <li
              v-for="el in convogliGeneratiIdList"
              :key="el.id"
            >
              <router-link :to="`/rails/ConvoyVisualization/${el.id}`" @click.native="onNewConvoyClick(el.id)">
                <span class="new-convoy-link">Vai a {{ el.nome }}</span>
              </router-link>
            </li>
          </ul>
          <v-divider/>
        </v-col> 
      </v-row>
    </v-container>
    <v-container fluid>
      <v-row v-if="convoglio.binarioId">
        <v-col>
          <v-tabs v-model="tabs">
            <v-tab v-if="!tabsRendered || tabsRendered[0]">Manovra</v-tab>
            <v-tab v-if="!tabsRendered || tabsRendered[1]">Manovra accessoria</v-tab>
            <v-tab v-if="!tabsRendered || tabsRendered[2]">Assegna viaggio</v-tab>
          </v-tabs>
          <v-tabs-items v-model="tabs" touchless>
            <v-tab-item v-if="!tabsRendered || tabsRendered[0]">
              <convoyGateAndMoveManagement 
                :convoglio="convoglio" 
                :parks="parks"
                :listOccupazione="listOccupazione"
              />
            </v-tab-item>
            <v-tab-item v-if="!tabsRendered || tabsRendered[1]">
              <convoyCuttingManagement 
                :convoglio="convoglio" 
                :parks="parks" 
                :newCreatedConvoyIdList="newCreatedConvoyIdList"  
              />
            </v-tab-item>
            <v-tab-item v-if="!tabsRendered || tabsRendered[2]">
              <convoyTripJoiningManagement 
                :convoglio="convoglio" 
                :parks="parks" 
              />
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col><p>Assegnare binario prima di eseguire manovre</p></v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import toastMixin from '../../../mixins/ToastMixin';
import multiParkManagementMixins from '../../../mixins/MultiParkManagementMixins';
import convoyVisualizationMixins from '../mixins/convoyVisualizationMixins';
import convoyGateAndMoveManagement from './ConvoyGateAndMoveManagement';
import convoyCuttingManagement from './ConvoyCuttingManagement';
import convoyTripJoiningManagement from './ConvoyTripJoiningManagement';

export default {  
  name: "convoyActionsManagement",
  data() {
    return {
      railPosition: 0,
      newCreatedConvoyIdList: [],
      tabs: null
    };
  },
  components: {
    convoyGateAndMoveManagement,
    convoyCuttingManagement,
    convoyTripJoiningManagement
  },
  props: {
    convoglio: {
      type: Object,
      required: true
    },
    listOccupazione: {
      type: Array,
      default: () => []
    },
    tabsRendered: {
      type: Array,
      default: () => [true, true, true],
      required: false
    }
  },
  mixins: [
    convoyVisualizationMixins, 
    toastMixin, 
    multiParkManagementMixins
  ],
  async mounted(){       
    await this.fetchData();
  },
  computed: {
    convogliGeneratiIdList() {
      return this.newCreatedConvoyIdList
    },
  },
  methods: {
    async fetchData() {
      await this.loadParks();
    },
    onNewConvoyClick(convoglioId){
      this._.remove(this.newCreatedConvoyIdList, el => el.id === convoglioId);
      this.newCreatedConvoyIdList = Object.assign([], this.newCreatedConvoyIdList);
    }
  },
};
</script>

<style scoped>
.new-convoy-link {
  color: blue;
}
</style>
