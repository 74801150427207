<template>
  <v-card tile>
    <v-card-text>
      <v-container fluid>
        <v-row>
          <v-col>
            <convoyWagonSelectionManagement 
              v-model="wagonSelectedList" 
              :convoglio="convoglio" 
              :parks="parks" 
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="6" md="3">
            <v-combobox
              clearable
              chips
              v-model="selectedTrip"
              :items="trips"
              label="Viaggio da associare"
              required
            >
              <template v-slot:item="{ item }">
                  {{ `${ (viewNomeViaggio() ? item.nome : item.codice) } - ${ item.nodoArrivo ? item.nodoArrivo.descrizione : 'NA' } - ${ item.etd }` }}
              </template>
              <template v-slot:selection="{ item }">
                  {{ `${ (viewNomeViaggio() ? item.nome : item.codice) } - ${ item.nodoArrivo ? item.nodoArrivo.descrizione : 'NA' } - ${ item.etd }` }}
              </template>
            </v-combobox>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" align="end">
            <v-btn 
              x-large
              @click="joinWagonsToDepartureTrip"
              color="primary"
            > 
              ASSEGNA PARTENZA
            </v-btn>            
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import toastMixin from '../../../mixins/ToastMixin';
import convoyVisualizationMixins from '../mixins/convoyVisualizationMixins';
import convoyWagonSelectionManagement from './ConvoyWagonSelectionManagement';
import railsMixins from '../../rails/mixins/railsMixins';
import moment from 'moment';

export default {  
  name: "convoyTripJoiningManagement",
  data() {
    return {
      wagonSelectedList: [],
      trips: [],
      selectedTrip: null
    };
  },
  components: {
    convoyWagonSelectionManagement,
  },
  props: {
    convoglio: {
      type: Object,
      required: true
    },
    parks: {
      type: Array,
      default: () => []
    },
  },
  mixins: [convoyVisualizationMixins, toastMixin,railsMixins],
  async mounted(){
    await this.fetchData();
  },
  computed: {
    newConvoyEnabled() {
      return this.selectedConvoy;
    },
  },
  methods: {
    async fetchData() {
      try {
        const currDate = moment().format('YYYY-MM-DD');
        const tsDateFrom = this.$utilities.dates.getTsFromDateFormat(moment().add(-1, 'days'));
				const tsDateTo = this.$utilities.dates.getTsFromDateFormat(moment(currDate).add(3, 'days'));
        this.trips = await this.$api.railData.findAllPartenzeProgrammate();
      } catch(e) {
        console.log(e);
      }
    },
    async joinWagonsToDepartureTrip() {
      try {
        if(!this.wagonSelectedList.length){
          this.showError('Selezionare almeno un carro da spostare');
          return;
        }
        if(!this.selectedTrip){
          this.showError('Selezionare un viaggio partenza');
          return;
        }
        const newConvoyRef = await this.$api.trainData.associaCarriViaggioPartenza(
          this.convoglio,
          this.selectedTrip, 
          this._.transform(this.wagonSelectedList, (res, value) => {
              res.push(value.id)
              return res;
            }, []));

        this.showSuccess(
          `Carri associati correttamente a viaggio ${ this.selectedTrip.codice } - ${ this.selectedTrip.nodoArrivo ? this.selectedTrip.nodoArrivo.descrizione : 'NA' } - ${ this.selectedTrip.etd }`);
          
          this.wagonSelectedList = [];
          this.selectedTrip = null;
      } catch (e) {
        this.showError(e);
      } finally {
        this.$eventBus.$emit('convoyReload');
      }
    }
  },
};
</script>
