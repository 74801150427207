<template>
  <v-card tile>
    <v-card-text>
      <!-- WSL--{{wagonSelectedList}}--WSL -->
      <v-container fluid>
        <v-row>
          <v-col>
            <convoyWagonSelectionManagement v-model="wagonSelectedList" :convoglio="convoglio" :parks="parks" />
          </v-col>
        </v-row>
        <v-row>
          <v-col v-if="!selectedPark" sm="6" md="3">
            <v-combobox clearable v-model="selectedConvoy" :items="convoyList" label="Convoglio a cui associare" item-text="nome" />
          </v-col>
          <v-col v-if="!selectedConvoy" sm="6" md="3">
            <v-combobox clearable v-model="selectedPark" :items="parks" label="Parco binari" item-text="codice" />
          </v-col>
          <v-col offset-sm="4" offset-md="2">
            <DateTimeAdapter label="Data / ora" v-model="operationDate" />
          </v-col>
        </v-row>
        <v-row v-if="rails.length && !selectedConvoy">
          <v-col sm="6" md="8">
            <v-radio-group v-model="selectedRail" row>
              <v-radio v-for="(rail, index) in rails" :key="index" :value="rail" :label="`B. ${rail.numero}`" />
            </v-radio-group>
          </v-col>
          <v-col sm="4" md="2">
            <v-text-field type="number" label="Posizione binario" :rules="[validationRules.greaterThanOrEqualToZero]" v-model="railPosition" />
          </v-col>
        </v-row>
        <v-row>
          <v-col offset-md="8" md="4" align="end">
            <v-btn x-large v-if="selectedConvoy" @click="joinWagonsToOtherConvoy" color="primary"> ASSOCIA A CONVOGLIO </v-btn>
            <v-btn x-large v-else @click="createNewConvoy" color="primary"> NUOVO CONVOGLIO </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import toastMixin from "../../../mixins/ToastMixin";
import convoyVisualizationMixins from "../mixins/convoyVisualizationMixins";
import convoyWagonSelectionManagement from "./ConvoyWagonSelectionManagement";
import DateTimeAdapter from "@/components/anagrafiche/DateTimeAdapter";

export default {
  name: "convoyCuttingManagement",
  data() {
    return {
      rails: [],
      selectedPark: null,
      selectedRail: null,
      selectedConvoy: null,
      wagonSelectedList: [],
      railPosition: 0,
      convoyList: [],
      operationDate: null,
    };
  },
  components: {
    convoyWagonSelectionManagement,
    DateTimeAdapter,
  },
  props: {
    convoglio: {
      type: Object,
      required: true,
    },
    parks: {
      type: Array,
      default: () => [],
    },
    newCreatedConvoyIdList: {
      type: Array,
      default: () => [],
    },
  },
  mixins: [convoyVisualizationMixins, toastMixin],
  async mounted() {
    await this.fetchData();
  },
  computed: {
    newConvoyEnabled() {
      return this.selectedConvoy;
    },
  },
  methods: {
    resetForm() {
      this.selectedPark = null;
      this.selectedRail = null;
      this.selectedConvoy = null;
      this.wagonSelectedList = [];
      this.railPosition = 0;
      this.operationDate = null;
    },
    async fetchData() {
      try {
        /*
        const currDate = moment().format("YYYY-MM-DD");
        const tsDateFrom = this.$utilities.dates.getTsFromDateFormat(
          moment().add(-1, "days")
        );
        const tsDateTo = this.$utilities.dates.getTsFromDateFormat(
          moment(currDate).add(3, "days")
        );
        
        const convoyList = await this.$api.railData.getConvogliByDateRange(
          tsDateFrom,
          tsDateTo
        );
        */
        const convoyList = await this.$api.railData.getConvogliInTerminal();

        this.convoyList = this._.filter(convoyList, (t) => t.stato && t.id !== this.convoglio.id);

        /*
      this.convoyList = this._.filter(
          convoyList,
          (t) =>
            t.stato &&
            t.stato.toLowerCase() === "interminal" &&
            t.id !== this.convoglio.id
        );
      */
        var date = new Date();
        date.setMinutes(date.getMinutes() + 1);
        this.operationDate = date;
      } catch (e) {
        console.log(e);
      }
    },
    async createNewConvoy() {
      try {
        if (this.operationDate == null) {
          this.showError("Data non valorizzata");
          return;
        }
        console.log("stato del convoglio ", this.convoglio.stato);
        if (this.convoglio.stato !== "INTERMINAL") {
          this.showError("Convoglio in stato diverso da INTERMINAL");
          return;
        }
        console.log("thi.wagonSelectedList.length", this.wagonSelectedList.length);
        console.log("this.convoglio.listCarri.length", this.convoglio.listCarri.length);
        if (this.wagonSelectedList.length === this.convoglio.listCarri.length) {
          this.showError("Impossibile eseguire la manovra accessoria , sono stati selezionati tutti i carri");
          return;
        }
        console.log("convoglio", this.convoglio);
        if (this.railPosition && this.railPosition < 0) {
          this.showError("Impostare un valore positivo per la posizione");
          return;
        }
        if (!this.wagonSelectedList.length) {
          this.showError("Selezionare almeno un carro da spostare");
          return;
        }
        if (!this.selectedRail) {
          this.showError("Selezionare il binario di destinazione");
          return;
        }

        const newConvoyRef = await this.$api.trainData.createNewConvoglio(
          this.convoglio,
          this._.transform(
            this.wagonSelectedList,
            (res, value) => {
              res.push(value.id);
              return res;
            },
            []
          ),
          this.selectedRail,
          this.railPosition,
          this.operationDate
        );

        this.newCreatedConvoyIdList.push(newConvoyRef);
        this.wagonSelectedList = [];
        this.showSuccess(`Nuovo convoglio creato correttamente: ${newConvoyRef.nome}`);
        this.resetForm();
      } catch (e) {
        console.log("Error createNewConvoy", e);
        const message = e.detailMessage ? e.detailMessage : e;
        this.showError(message);
      } finally {
        this.$eventBus.$emit("convoyReload");
        this.$eventBus.$emit("clearFilter");
        this.fetchData();
      }
    },
    async joinWagonsToOtherConvoy() {
      try {
        if (this.operationDate == null) {
          this.showError("Data non valorizzata");
          return;
        }
        if (!this.wagonSelectedList.length) {
          this.showError("Selezionare almeno un carro da spostare");
          return;
        }
        console.log("*******joinWagonsToOtherConvoy************");
        console.log("this.selectedConvoy: ", this.selectedConvoy);
        console.log("this.convoglio: ", this.convoglio);

        console.log("this.wagonSelectedList: ", this.wagonSelectedList);
        const newConvoyRef = await this.$api.trainData.associaCarriConvoglio(
          this.selectedConvoy,
          this.convoglio,
          this._.transform(
            this.wagonSelectedList,
            (res, value) => {
              res.push(value.id);
              return res;
            },
            []
          ),
          this.operationDate
        );

        this.newCreatedConvoyIdList.push(newConvoyRef);
        this.wagonSelectedList = [];
        this.showSuccess(`Carri associati correttamente ad altro convoglio: ${newConvoyRef.nome}`);
        this.resetForm();
      } catch (e) {
        console.log("Error joinWagonsToOtherConvoy", e);
        const message = e.detailMessage ? e.detailMessage : e;
        this.showError(message);
      } finally {
        this.$eventBus.$emit("convoyReload");
      }
    },
  },
  watch: {
    selectedPark: async function (newValue) {
      if (newValue) {
        this.rails = await this.$api.railData.getRailwayData(newValue.codice);
        this.selectedRail = this._.find(this.rails, (r) => this.convoglio.binario && r.id === this.convoglio.binario.id);
        this.railPosition = this.convoglio.posizioneBinario;
      } else {
        this.rails = [];
        this.selectedRail = null;
        this.railPosition = 0;
      }
    },
  },
};
</script>
